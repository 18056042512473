/*-------HEADER------------*/
body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}
:root {
    --main-bg-color: #EF7F1F;
    --text-color: #EF7F1F;
  }
.text-color {
  color: #EF7F1F;
}
.Header {
    background-color: var(--main-bg-color);
    font-size: 14px;
}
a {
  color: #18212e !important;
  text-decoration: none;
  font-size: 14px;
}
.SectionHeading {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  background-image: linear-gradient(to right, #ff7730, rgb(255 119 48 / 85%));
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}

/* .SectionHeading:hover {
  transform: skewY(2deg) skewX(15deg) scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgb(0 0 0 / 20%);
} */
/*-------NavBar------------*/
.dropdown-menu {
    border-radius: 0;
}
.active-link {
  color: #EF7F1F !important;
}
.carousel-caption {
  top: 50%;
}
.carousel-item img {
  position: relative;
  height: 66vh;
}
.navbar-nav .active {
  color: #EF7F1F !important;
  font-weight:bold;
}
/* .SliderOverlay {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
} */

/*-------About------------*/

.videoIcon button {
  border: none;
}
.video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background-color: #ff7730;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #EF7F1F;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(#EF7F1F, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
	border-top: 22px solid transparent;
	border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

  .aboutArea {
    padding: 2rem;
  }
.aboutImg {
  position: relative;
}
.videoIcon {
  position: absolute;
  top: 50%;
  left: 50%;
}
.videoIcon a {
  color: #FF0000;
  font-size: 48px;

}
  .aboutArea h4 {
    color: var(--main-bg-color);
  }
  .aboutArea h5 span {
    color: var(--main-bg-color);
    font-weight: 600;
}
.aboutArea p {
    text-align: justify;
}
.Services {
  background-color: #e9ecefad;
}
.Services .card {
  text-align: center;
  padding: 3rem;
  border-radius: 0;
  transition: .4s;
}
.Services .card:hover {
  border: 1px solid #EF7F1F;
  border-radius: 0;
}
.Blog .card {
  text-align: center;

  overflow: hidden;
}
.BlogContent {
  background: #fff;
  box-shadow: 3px 0 10px hsl(0deg 0% 53% / 10%);
  border-radius: 0 0 5px 5px;
  height: 120px;
}
.BlogContent h5 {
  color: #18212e;
  margin-bottom: 10px;
}
.Blog .card h5 {
  padding: 1rem;
}
.Blog .card img {
  display: block;
  width: 100%;

}

.ContactUs {
  background-image:
  linear-gradient(to bottom, rgba(0,0,0,.8), rgba(0,0,0,.8)),
  url('../images/banner.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.SubscribeWrapper {
  background-color: var(--main-bg-color);
  padding: 1rem;
}
.Subscribe {
  position: absolute;
  z-index: 999;
  width: 100%;
  top: -22%;
}
.Footer {
  color: #fff;
  background-image: linear-gradient(rgba(18,19,24,.9),rgba(18,19,24,.9)),url(../images/footer-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  background-position: center;
  padding-top: 60px;
  padding-bottom: 1rem;
  margin-top: 10rem;

}

.socailIcon ul {
  margin: 0;
  padding: 0;
}
.socailIcon ul li {
  list-style: none;
  display: inline-block;
}
.socailIcon ul li a {
  padding-right: 10px;
 
}
.facebook {
  color: #1190F2;
}
.linkedin {
  color: #0077B5;
}
.youtube {
  color: #FC0000;
}
.socailIcon ul li a svg {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #EF7F1F;
  border-radius: 50px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 5px;
  -webkit-transition: width .4s,-webkit-transform .4s;
}
.socailIcon ul li a svg:hover{
  -webkit-transform:rotate(360deg);
}
.Footer p {
  text-align: justify;
}
.FooterLink ul {
  margin: 0;
  padding: 0;
}
.FooterLink ul li {
  list-style: none;
}
.FooterLink ul li a {
  text-decoration: none;
  color: #fff !important;
  padding: 5px 0;
  display: block;
  transition: .4s;
}
.FooterLink ul li a:hover {
  color: var(--main-bg-color);
  margin-left: 4px;
}

/*--------------------------
Top Banner
-----------------------------*/
.TopBanner {
  color: #fff;
  background-image: linear-gradient(rgba(18,19,24,.9),rgba(18,19,24,.9)),url(../images/banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: 50%;
  overflow: hidden;
  height: 50vh;
  padding: 90px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-color {
  color: #EF7F1F;
}
.Details {
  margin-top: 3rem;
}
.Details p {
  color: #676767;
}
.DetailseContent, .BusinessSegment p {
  text-align: justify;
}
.DetailseContent h4 {
  color: var(--main-bg-color);
}
.DetailseContent p {
  color: #676767;
  text-align: justify;
}


.CountUp {
  background-image: linear-gradient(rgba(18,19,24,.9),rgba(18,19,24,.9)),url(../images/counter.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  padding: 6rem;
  background-attachment: fixed;
}
.CountUp h2 {
  color: var(--main-bg-color);
}

/*--------------------------
Button Primary
-----------------------------*/
.Btn:link {
  text-decoration: none;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
  border: none;
  cursor: pointer;
}
.BtnAnimated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}
.BtnOrange {
  background-color: var(--main-bg-color);
  color: #fff !important;
}
.Btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
}
.Btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}
.BtnOrange::after {
  background-color: var(--main-bg-color);
}
.BtnOrange:hover::after {
  transform: scaleX(1.4) scale(1.6);
  opacity: 0;
}

/*-------------------
LATEST WORKS
-----------------------*/
.WorkCard {
  position: relative;
}

.CardOverlay {
  position: absolute;
  background: rgba(106, 103, 94, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: .7s;
  -moz-transition: .7s;
  -ms-transition: .7s;
  -o-transition: .7s;
  transition: .7s;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--main-bg-color);
}

.WorkCard:hover .CardOverlay {
	opacity: 1;
	transform: scale(1);
}

/*-----------------------
Our Team
--------------------------*/
.OurTeam {
  border: none;
  border-radius: 0;
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  padding: 30px 20px 30px;
  text-align: center;
}
.OurTeam img {
  text-align: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #EF7F1F;
  padding: 5px;
  display: inline-block;
  margin-bottom: 30px;
}


.NewsPost {
  border: 2px solid #EF7F1F;
}

/*----------------------
Call center services
------------------------*/
.ServicesSideBar {
  background-color: #fff;
  box-shadow: 3px 4px 30px hsl(0deg 0% 53% / 10%);
  padding: 40px 30px;
  margin-bottom: 50px;
}
.ServicesSideBar ul {
  margin: 0;
  padding: 0;
}
.ServicesSideBar ul li {
  border: 1px solid #eee;
  padding: 10px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all .45s ease-in-out;
}
.ServicesSideBar ul li:hover {
  background-color: #EF7F1F;
}
.ServicesSideBar ul li a {
  font-size: 19px;
  font-weight: 400;
  color: #18212e;
  transition: all .45s ease-in-out;
}

/*------------------------
Responsive
--------------------------*/
@media (max-width: 1200px) {
  .fw-12 {
    font-size: 12px;
  }
}
@media (max-width: 980px) { 
  .Header {
    display: none;
  }
  .ContactUs {
    padding: 0 !important;
  }
  .carousel-item img {
      height: 30vh;
      background-size: cover;
      background-position-x: center;
  }
  .SubscribeWrapper {
    margin-top: 4rem;
}
.navbar-expand .navbar-nav {
  flex-direction: column;
}
  }
  @media (max-width: 1024px) { 
    .carousel-item img {
        height: 30vh;
        background-size: cover;
        background-position-x: center;
    }
    }


.blog-details p {
  text-align: justify;
}
.copy-right {
  background-color: #14191D;
  padding: 1rem 0;
}
.member-logo img {
  border: 1px solid #fff;
  margin: .5rem .5rem;
  padding: 0 .5rem;
}
.member-logo p {
  margin: 0;
  color: #fff;
}

.single-service.style-two {
  cursor: pointer;
  background-color: #ffffff;
  height: 400px;
  border: 1px solid #f5f7f8;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 45px
px
;
  padding: 1rem;
  transition: all 0.3s ease 0s;
}
.img-fluid-2 {
  width: 44px;
  height: 48px;
}
.text-color {
  color: #EF7F1F;
}